import React from 'react';
import { BrowserRouter } from 'react-router-dom';


/* plugins */
/* import { Splash } from '../commons/splash'; */

/* routes */
import Routes from './Routes';
function App() {
  /* const [cmp, setCmp] = useState(<Splash />)
  useEffect(() => {
    setTimeout(() => {
      setCmp(<Routes />)
    }, 3000)
  },[]) */

  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
}

export default App;
