/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import Page from '../../components/commons/page';
const Support = () => {


    return (
        <Page
            fab
            title="Contato/Suporte"
        >
            Para melhor atender nosso associado contamos com o Suporte, um canal onde você pode tirar suas dúvidas de funcionamento do seu app, bem como fornecemos um serviço exclusivo de busca personalizada via WhatsApp, através desse canal nossos atendentes farão o filtro do benefício desejado para sua comodidade.
            <br />
            Nosso chat online funciona de segunda a sexta-feira das 09:00 as 17:00.
            <br />
            <a href="https://wa.me/5511969132413"></a>

        </Page>
    )
}

export default Support
