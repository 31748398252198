import React from 'react'
import Page from '../../../../components/commons/page';
import { getStorage } from '../../../../utils';
const Pharmacy = () => {
    const text = `
        Aqui você encontra diversas opções de farmácias em todo Brasil, com as maiores redes e milhares de unidades espalhadas pelo país
        Aproveite os descontos que variam de 5% a 70 % em medicamentos, produtos manipulados, cosméticos entre outros.
    `
            
    const user = getStorage('@auth')
    return (
        <Page 
            // frame={`http://allsul.com.br/app/credenciados/index.php?usuario_logado=${user.login}`}
            title="Farmácias"
            text={text} 
        />
    )
}

export default Pharmacy
