import React from 'react'

const Button = (props: any) => {
    return (
    <> 
        { props?.loading ? 
        <div className="pt-2">
            <div className="spinner-border text-success" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div> :  
        <button 
            type={props.type}
            className={props?.classList} 
            onClick={props.handleClick}>
                {props.label}
        </button> }
    </>)
    
}
export default Button
