import React from 'react'
import { useHistory } from 'react-router-dom'
import { BoxBtn } from '../../../../components/commons/box-btn'
import Header from '../../../../components/commons/header'

const Seguros = () => {
    const history = useHistory()
    return (
        <>
            <Header />
            <div className={"d-flex flex-column"}>
                <BoxBtn label={'Acidentes pessoais Porto Seguro'} classList="btn-customs" onClick={() => history.push("/porto-seguro")}/>
                <BoxBtn label={'MQC + Assistências Sul América Vida'} classList="btn-customs" onClick={() => history.push("/mqc")}/>
            </div>
        </>
    )
}
export  default Seguros