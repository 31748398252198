import React from 'react'

const Input = (props: any) => {
    return (
        <>
        <label>{props.label}</label>
          <input
            id={props.id}
            className={props?.classList}
            type={props?.secret ? 'password' : props.type} 
            name={props?.name}
            onChange={props.handleChange}
            placeholder={props?.placeholder}/>
    </>)
}
export default Input
