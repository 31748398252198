import React from 'react'
import Page from '../../components/commons/page';
const LeituraPlay = () => {
    const text = `
        Contamos com diversos livros, conheça nosso catologo revistas, e-books e audiobooks, acesse já.`
    return (
        <Page
            href={`https://clube.minhabiblioteca.com.br/account`}
            title="Minha biblioteca"
            text={text}
        />
    )
}

export default LeituraPlay
