import React from 'react'
import Page from '../../../../../../components/commons/page'

const PortoSeguro = () => {
    return (
        <>
            <Page
                pdf 
                href="https://economiainteligente.app/files/porto-seguro.pdf"
                title="Acidentes pessoais Porto Seguro"  
            >
               O Seguro de Acidentes Pessoais Porto Seguro tem por objetivo o pagamento de um
               capital segurado no valor de até R$ 40.000,00 aos associados, caso sofram
               algum acidente pessoal, além disso contamos com auxílio funeral de até R$ 3.000,00 e cesta básica por 12 meses ao amparado.
               Em caso de sinistro ou dúvidas entrar em contato com a central de relacionamento Porto seguro 24 horas:
               {<a href="tel:0800-7272746"> 0800-7272746</a>} 
            </Page>
        </>
    )
}

export default PortoSeguro
