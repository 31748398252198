import moment from 'moment'
export const handleClick = (e: any, url: string) => {
    e.preventDefault();
    window.location.href = url
}

export const formatDate = (days: number) => {
    const now = new Date()
    const nowAux = new Date(now)
    const validDate = new Date(nowAux.setDate(+now.getDate() + days))
    return moment(validDate).format('DD/MM/YYYY')
}

export const getStorage = (key: string) => {
    const data: any = localStorage.getItem(key)
    return JSON.parse(data)
}

export const setStorage = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
}