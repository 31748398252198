import React from 'react'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
/* logo */
// import logo from '../../../assets/logo.png'
import logo from '../../../assets/logo-1.png'
import "./-logo_styles.scss"
import { useHistory } from 'react-router-dom';

const LogoHeader = (props: any) => {
    const history = useHistory()
    const exitApp = () => {
        localStorage.clear()
        history.push('/')
    }
    return (
        <header className="d-flex col-lg-4 col-md-4 col-sm-12 flex-row justify-content-center">
            {props.logoff ? <div className="icon-logout" onClick={() => exitApp()}>
                <ExitToAppIcon />
            </div> : null}
            <img src={logo} width={props.width} height={props.height} alt="Economia Inteligente" />
        </header>
    )
}
export default LogoHeader