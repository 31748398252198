import React from 'react'
import Page from '../../components/commons/page';
const Discount = () => {
    const text = `
        Além dos benefícios listados, na página de convênios aqui você encontra mais parceiros com benefícios incríveis, para acessar essa área é necessário login e senha, se for o primeiro acesso faça seu cadastro a partir do seu CPF.    
   ` 
    return (
        <Page 
            href={`https://www.cuponomia.com.br/`}
            title="+ Descontos" 
            text={text} 
        />
    )
}

export default Discount
