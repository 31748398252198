import React from 'react'
import Page from '../../../../../../components/commons/page'

const MQC = () => {
    return (
        <>
            <Page
                pdf 
                href="https://economiainteligente.app/files/mqc.pdf"
                title="MQC + Assistências Sudamérica Vida"  
            >
                O Seguro de morte por qualquer causa Sudamérica vida tem por objetivo o pagamento de um capital segurado no valor de até R$ 10.000,00 aos associados em caso de morte por qualquer causa, além disso contamos com auxílio funeral de até R$ 3.000,00.
                O associado também pode contar com as assistências Auto e residencial que auxilia nos serviços de troca de pneus, socorro mecânico, reboque, encanador, chaveiro, eletricista entre outros.
                Consulte as coberturas e valores baixando o PDF no botão abaixo
                Central de atendimento Sulamérica vida:
                {<a href="tel:(41)2170-1220">(41) 2170-1220</a>} / {<a href="tel:08008887832">0800 888 7832</a>} 
            </Page>
        </>
    )
}

export default MQC
