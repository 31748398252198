import React from 'react'
import Page from '../../components/commons/page';
import logo from '../../assets/logo-1.png'


const Agreement = () => {
    return (
        <Page title="Convênios">
            <a href="https://club.mediceduc.com.br" target="_blank" rel="noreferrer">
                <img src={logo} alt="Club Mediceduc" width={150} />
            </a>
        </Page>
    )
}

export default Agreement
