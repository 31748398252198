import React from 'react'
import Page from '../../components/commons/page';
const Cashback = () => {
    return (
        <Page 
            href={`https://mediceduc.suasvantagens.com.br`}
            title="Convênios 2"  
        />
    )
}

export default Cashback
