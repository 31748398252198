import React from 'react'
import Page from '../../../../components/commons/page';
const Telemedicine = () => {
    
    return (
        <Page 
            title="Telemedicina"  
        >
            Agora você conta com um serviço de teleconsulta na comodidade de sua casa ou trabalho
            sem a necessidade de se deslocar para o consultório.
            Além de poder realizar consultas médicas de qualquer lugar e dispor dos melhores médicos
            em diversas especialidades, você poderá acessar suas receitas,
            pedidos de exames e atestados de qualquer lugar, você receberá por e-mail, com validade
            em todo território nacional com total comodidade.
            Para acessar a área da Telemedicina siga os passos abaixo:
            <ul className="text-left">
                <li>Clique no botão abaixo;</li>
                <li>Abrirá uma nova página para fazer login na área restrita;</li>
                <li>Caso seja o primeiro acesso, digite o CPF e cadastre seus dados;</li>
                <li>Após o login procure no buscador: Telemedicina ou localize no menu do site;</li>
                <li>Pronto, agora você já pode desfrutar de mais esse benefício incrível!</li>
            </ul>
        </Page>
    )
}

export default Telemedicine
