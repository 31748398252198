import React from 'react'
import Page from '../../../../components/commons/page';
const ClubFerias = () => {

    return (
        <Page 
            href={`https://clubdeferias.tur.br/`}
            title="Club Férias" 
        >
            Com uma rede de mais de 5.000 hospedagens espalhadas pelo Brasil, oferecemos descontos exclusivos que chegam até 70%.
            {<br />}
            São os mais variados destinos em estabelecimentos de qualidade, não frustre suas férias, hospede através do Club De Férias.
            {<br />}
            Para realizar sua reserva entre em contato com o Club de Férias nos telefones:
            {<a href="tel:(11)3101-0002">(11)3101-0002<br/></a>} 
            {<a href="tel:(11)3101-4002">(11)3101-4002<br/></a>} 
            WhatsApp {<a href="https://api.WhatsApp.com/send?phone=+55(11)99872-4807">(11)99872-4807<br/></a>} 
            E-mail: {<a href="mailto:reservas@clubdeferias.com.br">reservas@clubdeferias.com.br</a>}
        </Page>
    )
}

export default ClubFerias
