import React from 'react'
import Page from '../../../../components/commons/page';
import "./-allsul-cruzeiros_styles.scss"
const AllsulCruzeiros = () => {

 
    return (
        <Page 
            href={`https://clubedoturista.com.br/`}
            title="All Sul Turismo" 
        >
            Aqui você encontra os melhores passeios de cruzeiros com as melhores companhias e os menores preços.
            <br/>
            Inclusos em todos os cruzeiros:
            <ul className="text-align-left">
                <li>Restaurante à la carte e buffet por 20 horas.</li>
                <li>Acomodações com conforto superior.</li>
                <li>Entretenimento de nível mundial.</li>
                <li>Instalações de última tecnologia.</li>
                <li>Renomado serviço a bordo.</li>
                <li>Atividades para famílias.</li>
            </ul>
            {/* Recomendamos que as reservas sejam realizadas com 30 a 60 dias de antecedência. */}
            Faça sua reserva via 
            <br/>WhatsApp <a href="https://api.whatsapp.com/send?phone=+55(41)98418-3385">(41) 98418-3385</a>, 
            <br/>pelo telefone <a href="tel:(41)98418-3385"> (41) 3232-4007</a> ou 
            <br/>clique no botão abaixo e faça sua reserva pelo site agora mesmo!
        </Page>
    )
}

export default AllsulCruzeiros
