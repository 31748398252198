import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

/* login */
import Login from '../../features/Login'
/* pages */
/* Home - Pagina principal */
import Home from '../../features/Home'
/* Cartão Virtual */
import Card from '../../features/Card'
/* cashback */
import Cashback from '../../features/Cashback'
/* Turismo */
import Tour from '../../features/Tour'
import ClubFerias from '../../features/Tour/subpages/ClubFerias'
import AllsulCruzeiros from '../../features/Tour/subpages/AllsulCruzeiros'
/* Saúde */
import Health from '../../features/Streaming'
import Pharmacy from '../../features/Streaming/subpages/Pharmacy'
import Consultation from '../../features/Streaming/subpages/Streamings'
import Telemedicine from '../../features/Streaming/subpages/Telemedicine'
/* Serviços */
import Services from '../../features/Services'
import Seguros from '../../features/Services/subpages/Seguros'
import PlanoOdontologicos from '../../features/Services/subpages/PlanoOdontologicos'
/* + Descontos */
import Discount from '../../features/Discount'
/* Educação */
import Education from '../../features/Education'
/* Serviços Gratuitos */
import SelfServices from '../../features/SelfServices'
/* convenios */
import Agreement from '../../features/Agreement'
import MQC from '../../features/Services/subpages/Seguros/subpages/MQC'
import PortoSeguro from '../../features/Services/subpages/Seguros/subpages/PortoSeguro'
import Support from '../../features/Support'
import LeituraPlay from '../../features/LeituraPlay'
import PageCommon from '../commons/page-common'
import Streaming from '../../features/Streaming'

const routeList = [
    {
        path: '/',
        component: Login,
        exact: true,
        private: false
    },
    {
        path: '/home',
        component: Home,
        exact: true,
        private: true
    },
    // {
    //     path: '/self-services',
    //     component: SelfServices,
    //     exact: true,
    //     private: true
    // },
    // {
    //     path: '/streaming',
    //     component: Streaming,
    //     exact: true,
    //     private: true
    // },
    {
        path: '/services',
        component: Services,
        exact: true,
        private: true
    },
    // {
    //     path: '/card',
    //     component: Card,
    //     exact: true,
    //     private: true
    // },
    {
        path: '/tour',
        component: Tour,
        exact: true,
        private: true
    },
    {
        path: '/education',
        component: Education,
        exact: true,
        private: true
    },
    {
        path: '/leitura-play',
        component: LeituraPlay,
        exact: true,
        private: true
    },
    {
        path: '/discount',
        component: Discount,
        exact: true,
        private: true
    },
    {
        path: '/cashback',
        component: Cashback,
        exact: true,
        private: true
    },
    {
        path: '/consultation',
        component: Consultation,
        exact: true,
        private: true
    },
    {
        path: '/pharmacy',
        component: Pharmacy,
        exact: true,
        private: true
    },
    {
        path: '/telemedicine',
        component: Telemedicine,
        exact: true,
        private: true
    },
    {
        path: '/allsul-cruzeiros',
        component: AllsulCruzeiros,
        exact: true,
        private: true
    },
    {
        path: '/club-ferias',
        component: ClubFerias,
        exact: true,
        private: true
    },
    {
        path: '/planos-odontologicos',
        component: PlanoOdontologicos,
        exact: true,
        private: true
    },
    {
        path: '/seguros',
        component: Seguros,
        exact: true,
        private: true
    },
    {
        path: '/agreement',
        component: Agreement,
        exact: true,
        private: true
    },
    {
        path: '/mqc',
        component: MQC,
        exact: true,
        private: true
    },
    {
        path: '/porto-seguro',
        component: PortoSeguro,
        exact: true,
        private: true
    },
    {
        path: '/support',
        component: Support,
        exact: true,
        private: true
    },
    {
        path: '/page-common',
        component: PageCommon,
        exact: true,
        private: false
    }


]

const PrivateRoute = (props: any) => {
    const auth = localStorage.getItem('@auth')
    return auth ? (
        <Route {...props} />
    ) : (
        <Route component={() => <Redirect to="/login" />} />
    )
}

const RouteBuilder = (route: any) => {
    return (
        <Route
            exact={!!route.exact}
            path={route.path}
            render={props => (<route.component {...props} />)} />
    )
}

export default function Routes() {
    return (
        <Switch>
            {routeList.map((route, key) => {
                if (!route.private) {
                    return <RouteBuilder key={key} {...route} />
                } else {
                    return <PrivateRoute key={key} {...route} component={route.component} />
                }
            })}

            <Redirect from="*" to="/" />
        </Switch>
    )
}
