import React from 'react'
import Header from '../header';
import { handleClick } from '../../../utils/';
import FabButton from '../fab-button';
import "./_page-styles.scss"
import 'react-tiny-fab/dist/styles.css';

interface Props {
    title: string
    children?: any
    href?: string
    frame?: string
    login?: string
    text?: string
    pdf?: boolean
    fab?: boolean
}
const Page = ({ title, children, href, frame, text, pdf, fab }: Props) => {

    return (
        <>
            <Header />
            <div className={"d-flex flex-column bg-black c_education"}>
                <div className="c_education_header">
                    <span>{title}</span>
                </div>
                <div className="c_education_body">
                    {text ? text : children}
                </div>
                { href ? <div className="c_education_button">
                    <button type="button" onClick={(event: any) => handleClick(event, href)}>
                        { pdf ? 'Baixe agora o PDF clicando aqui!' : 'Acesse agora clicando aqui!'}
                    </button>
                </div> : null }
                { frame ? <div className="c_education_frame">
                    <div className="c_education_frame_title">
                        Faça o filtro por região e selecione a categoria.
                    </div> 
                <iframe title="All Sul Convênios" src={frame} frameBorder="0" scrolling="yes"></iframe>
                </div> : null }
            </div>
            { fab ? <FabButton /> : null}
        </>
    )
}

export default Page
