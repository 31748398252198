import React from 'react'

import Page from '../../../../components/commons/page';
const PlanoOdontologicos = () => {

    return (
        <>
            <Page 
                pdf
                href="https://economiainteligente.app/files/apresentacao_odonto_bronze%20_porto.pdf"
                title="Plano odontológico"  
            >
                Com o plano odontológico Porto seguro você tem acesso a:
                <br/>
                Diagnóstico, urgência, emergência, radiologia, prevenção, odontopediatria, dentistica, endodontia, periodontia, cirurgia oral menor e prótese.
                <br/>
                Contato para agendar consultas 
                <br/>
                <a href="tel:08007272762">0800 727 2762</a> 
                <br/>
                (atendimento 24 horas)
                <br/>
                Você pode baixar a tabela com as coberturas detalhadas clicando abaixo:
            </Page>
        </>
    )
}

export default PlanoOdontologicos
