import React from 'react';
import './_box-btn.scss';

interface Props {
    icon?: any
    label?: string
    classList?: string
    onClick?: any
    href?: any
}

export const BoxBtn = ({ icon, classList, label, onClick }: Props) => {
    return (
        <div onClick={onClick} className={`box-btn d-flex flex-column justify-content-center align-items-center ${icon ? 'col-auto' : ''} ${classList}`} >
          <div className="d-flex">{icon}</div>
          <div className="d-flex">{label}</div>
        </div>
    )
}
