import React from 'react'
import "./-tour-style.scss"
import Page from '../../components/commons/page'

const Tour = () => {
    return (
        <Page
            pdf
            href="https://mediceduc.com.br/assets/turismo-2024.pdf"
            title="Manual do usuário"
        >
            Hotéis e cruzeiros Mac com 30% off
            <br />WhatsApp <a href="https://wa.me/5511964385674">(11) 96438-5674</a>,
        </Page>
    )
}
export default Tour