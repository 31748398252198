import React from 'react'
import Page from '../../../../components/commons/page';
const Streamings = () => {

    return (
        <Page
            href={`http://web.scorpionplay.online/index.php`}
            title="Streaming"
        />
    )
}

export default Streamings
 