import React from 'react'
import Page from '../../components/commons/page';
import { getStorage } from '../../utils';
const Education = () => {
    const text = `
        Contamos com Milhares de estabelecimentos credenciados por todo o país.
        Descontos incríveis que vão de 5% a 45% nas mensalidades de universidades, colégios, escolas de idiomas, cursos profissionalizantes entre outros, faça sua busca por região e economize muito com esse benefício!`
    // const user = getStorage('@auth')
    return (
        <Page
            href={`https://www.educamaisbrasil.com.br/`}
            title="Educação"
            text={text}
        />
    )
}

export default Education
