import React from 'react'
import Page from '../../../components/commons/page';
const PageCommon = () => {

    return (
        <Page
            title="Em breve"
        >
            Em manutenção
        </Page>
    )
}

export default PageCommon
