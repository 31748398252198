import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
/* component */
import LogoHeader from '../../components/commons/logo'
import { getStorage, setStorage } from '../../utils/'
import Button from './commons/Button'
import Input from './commons/Input'
/* style */
import './_login-style.scss'
interface Props {

}

const Login = (props: Props) => {
    const [login, setFormLogin] = useState("")
    const [senha, setFormPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    useEffect(() => {
        const auth = getStorage('@auth')
        if (auth) {
            history.push('/home')
        }
    }, [history])


    const submitClick = async (e: any) => {
        e.preventDefault()
        setLoading(true)

        const data = { cpf: login, password: senha }
        try {
            if (process.env.NODE_ENV === 'development') {
                const date = new Date(new Date().getTime() + 60)
                const timestamp = date.getTime()
                setStorage('@auth', { expireIn: date.toISOString(), timestamp })
                history.push('/home');
                return
            }

            const myHeaders = new Headers();
            myHeaders.append("API-Secret", "927339c5fd3e901bd67f4e180f3e1ec148886a4dc96f54395a6e7b95fe8e88722e7425d3a63ef9aaf7eca41221fcff4a0f614d6ce4959fefc77d3776d9638b10985c1708da858fae307dc0f486091f1dc389ccf935f1cb1e0ca404fd111082b1c0082f8b");
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify(data);

            const requestOptions: RequestInit = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow" as RequestRedirect
            };

            const response = await fetch("https://api.lecupon.com/api/v1/public_integration/auth/sign_in",
                requestOptions);

            if (response.ok) {
                const date = new Date(new Date().getTime() + 60)
                const timestamp = date.getTime()
                setStorage('@auth', { expireIn: date.toISOString(), timestamp })
                history.push('/home');
            }

        } catch (error) {
            console.error(error)
            alert("Erro ao tentar acessar o servidor");
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="d-flex flex-column login-style">
            <div className="d-flex justify-content-center align-items-center">
                <LogoHeader width="200" height="100" />
            </div>
            <div className="d-flex justify-content-center align-items-center login-box shadow p-3 mb-5 rounded">
                <form className="d-flex flex-column justify-content-center align-items-center login-form">
                    <Input label="Usuário" name="cpf" classList="input-style" handleChange={(event: any) => setFormLogin(event.target.value)} />
                    <Input secret label="Senha" classList="input-style" handleChange={(event: any) => setFormPassword(event.target.value)} />
                    <Button loading={loading} label="ENTRE" classList="btn-style" handleClick={(event: any) => submitClick(event)} />
                    {/* <Button label="CADASTRE-SE" classList="btn-style" handleClick={(event: any) => handleClick(event, "http://allsul.com.br/app/desbloqueio.html")} /> */}
                    {/* <span onClick={(event: any) => handleClick(event, "http://allsul.com.br/app/senha.html")}>ESQUECI A SENHA</span> */}
                </form>
            </div>
        </div>
    )
}
export default Login
