import React from 'react'
import "./-services-style.scss"
import Page from '../../components/commons/page'

const Services = () => {
    return (
        <Page
            pdf
            href="https://mediceduc.com.br/assets/guia-mediceduc-2024.pdf"
            title="Manual do usuário"
        >
        </Page>
    )
}
export default Services