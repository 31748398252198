import React from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import "./-header-style.scss"
import { useHistory } from 'react-router-dom';

const Header = () => {
    const history = useHistory()
    return (
        <div className="d-flex flex-fill c_header">
            <div className="d-flex justify-content-center c_header_icon" onClick={() => history.goBack()}><ArrowBackIcon /></div>
            <div className="d-flex flex-fill flex-column justify-content-center align-items-center align-content-center c_header_label">
                <span>Voltar</span>
            </div>
        </div>
    )
}
export default Header