import React, { useState } from 'react'
import { Fab, Action } from 'react-tiny-fab';
import ForumIcon from '@material-ui/icons/Forum';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Twitter from '@material-ui/icons/Twitter'
import Pinterest from '@material-ui/icons/Pinterest'
import { handleClick } from '../../../utils';
import 'react-tiny-fab/dist/styles.css';

const FabButton = () => {
    const [show, setShow] = useState(false)
    const handleFabClick = () => {
        const att = document.querySelector(".rtf")
        if (show) {
            att?.setAttribute("class", "rtf closed")
        } else {
            att?.setAttribute("class", "rtf open")
        }
        setShow(!show)
    }

    return (
        <Fab
            icon={<ForumIcon />}
            alwaysShowTitle={true}
            mainButtonStyles={{ backgroundColor: '#05ce0f' }}
            style={{ right: 1 / 12 * 3 * 50, bottom: 0 }}
            onClick={() => handleFabClick()}
        >
            <Action
                style={{ backgroundColor: '#06D109' }}
                text="Whats"
                onClick={(event: any) => handleClick(event, "https://wa.me/5511969132413")}
            >
                <WhatsAppIcon />
            </Action>
            {/* <Action
                style={{ backgroundColor: '#CE06D1'}}
                text="Instagram"
                onClick={(event: any) => handleClick(event, "https://instagram.com/economiainteligenteapp")}
            >
                <InstagramIcon />
            </Action>
            <Action
                style={{ backgroundColor: '#3371FF'}}
                text="Facebook"
                onClick={(event: any) => handleClick(event, "https://www.facebook.com/economiainteligenteapp/")}
            >
                <FacebookIcon />
            </Action>
            <Action
                style={{ backgroundColor: '#6891f1'}}
                text="Twitter"
                onClick={(event: any) => handleClick(event, "https://twitter.com/AppEconomia")}
            >
                <Twitter />
            </Action>
            <Action
                style={{ backgroundColor: 'red'}}
                text="Pinterest"
                onClick={(event: any) => handleClick(event, "https://br.pinterest.com/Economiainteligenteapp/")}
            >
                <Pinterest />
            </Action>
            <Action
                style={{ backgroundColor: '#021898'}}
                text="Email"
                onClick={(event: any) => handleClick(event, "mailto:atendimento@economiainteligente.app.br")}
            >
                <MailOutlineIcon />
            </Action> */}
        </Fab>
    )
}

export default FabButton
