import React from 'react';
import './_home.scss';

/* plugins */
import { useHistory } from "react-router-dom";
/* components/commons */
import { BoxBtn } from '../../components/commons/box-btn';

/* icons material design */
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LocalGroceryStoreRoundedIcon from '@material-ui/icons/LocalGroceryStoreRounded';
import NewReleasesOutlinedIcon from '@material-ui/icons/NewReleasesOutlined';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import FlightIcon from '@material-ui/icons/Flight';
import CastIcon from '@material-ui/icons/Cast';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import GroupIcon from '@material-ui/icons/Group';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LogoHeader from '../../components/commons/logo';
import MoneyIcon from '@material-ui/icons/Money';

export const _LOGO_ = "https://economiainteligente.app.br/wp-content/uploads/2020/11/logo-oficial-ok-real-e1606420045575.png"

const Home = () => {

  const history = useHistory()

  return (
    <div className="d-flex flex-column align-items-center">
      <LogoHeader width="350" height="225" logoff />
      <div className={"d-flex col-lg-4 col-md-4 col-sm-12 flex-row justify-content-center"}>
        <BoxBtn onClick={() => history.push('/agreement')} icon={<LocalGroceryStoreRoundedIcon />} label={'Convênios'} />
        <BoxBtn onClick={() => history.push('/discount')} icon={<MonetizationOnIcon />} label={'+ Descontos'} />
      </div>
      <div className={"d-flex col-lg-4 col-md-4 col-sm-12 flex-row justify-content-center"}>
        {/* <BoxBtn onClick={() => history.push('/streaming')} icon={<CastIcon />} label={'Streaming'} /> */}
        <BoxBtn onClick={() => history.push('/tour')} icon={<FlightIcon />} label={'Turismo'} />
        <BoxBtn onClick={() => history.push('/services')} icon={<GroupIcon />} label={'Manual do usuário'} />
      </div>
      <div className={"d-flex col-lg-4 col-md-4 col-sm-12 flex-row justify-content-center"}>
        {/* <BoxBtn onClick={() => history.push('/cashback')} icon={<MoneyIcon />} label={'Convênios 2'} /> */}
        {/* <BoxBtn onClick={() => history.push('/tour')} icon={<FlightIcon />} label={'Turismo'} /> */}
      </div>
      <div className={"d-flex col-lg-4 col-md-4 col-sm-12 flex-row justify-content-center"}>
        {/* <BoxBtn onClick={() => history.push('/self-services')} icon={<NewReleasesOutlinedIcon />} label={'Serviços gratuítos'} /> */}
        {/* <BoxBtn onClick={() => history.push('/card')} icon={<CreditCardIcon />} label={'Cartão Virtual'} /> */}
      </div>
      <div className={"d-flex col-lg-4 col-md-4 col-sm-12 flex-row justify-content-center"}>
        <BoxBtn onClick={() => history.push('/education')} icon={<LocalLibraryIcon />} label={'Educação'} />
        <BoxBtn onClick={(event: any) => history.push('/leitura-play')} icon={<AnnouncementOutlinedIcon />} label={'Minha biblioteca'} />
      </div>
      <div className={"d-flex col-lg-4 col-md-4 col-sm-12 flex-row justify-content-center"}>
        <BoxBtn onClick={(event: any) => history.push('/support')} icon={<AnnouncementOutlinedIcon />} label={'Contato Suporte'} />
        <BoxBtn onClick={() => history.push('/cashback')} icon={<MoneyIcon />} label={'Convênios 2'} />
        {/* <BoxBtn onClick={(event: any) => history.push('/leitura-play')} icon={<AnnouncementOutlinedIcon />} label={'LeituraPlay'} /> */}
      </div>
    </div>
  )
}
export default Home